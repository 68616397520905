<template>
  <a-button class="m-2" shape="round" :size="size">{{ text }}</a-button>
</template>
<script>
import { defineComponent, ref } from "vue";
export default defineComponent({
  props: ["text"],
  setup() {
    return {
      size: ref("large"),
    };
  },
});
</script>
