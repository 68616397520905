<template>
  <section class="newsletter">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <h4><b>Join our newsletter</b></h4>
          <p class="pt-2">
            Stay in touch with us with the latest insights and research
            delivered straight to your inbox
          </p>
        </div>
        <div class="col-md-5">
          <div class="content">
            <form>
              <div class="input-group">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Enter your email"
                />
                <span class="input-group-btn">
                  <button class="btn" type="submit">Subscribe</button>
                </span>
              </div>
            </form>
          </div>
          <p class="pt-4">
            By subscribing you agree to with our <a href="">Privacy Policy</a>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  components: {},
};
</script>
<style>
@media (max-width: 991px) {
  .newsletter {
    padding: 50px 30px !important;
  }
}
.newsletter {
  padding: 50px 0px;
  background: #f9f9f9;
}

.newsletter .content {
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 2;
}
.newsletter .content h2 {
  color: #243c4f;
  margin-bottom: 40px;
}
.newsletter .content .form-control {
  height: 50px;
  border-color: #000;
  border-radius: 0;
}
.newsletter .content.form-control:focus {
  box-shadow: none;
  border: 2px solid #243c4f;
}
.newsletter .content .btn {
  min-height: 50px;
  border-radius: 0;
  background: #243c4f;
  color: #fff;
  font-weight: 600;
}
</style>
