<template>
  <div>
    <section class="About" v-if="about.id">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <h2>{{ about.title }}</h2>
            <p class="pt-2">
              {{ about.subtitle }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 p-4">
            <h2>{{ about.about_title }}</h2>
          </div>
          <div class="col-md-6 p-4">
            <p>{{ about.about_content }}</p>
            <p class="pt-2">{{ about.about_content_2 }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 p-4">
            <img src="../assets/images/about-us.jpg" class="aboutimage" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 p-4">
            <h3>{{ about.achievements_title }}</h3>
            <p>{{ about.achievements_description }}</p>
            <div class="row p-2">
              <div class="col-md-6 text-center">
                <div class="col-md-12">
                  <h3>{{ about.projects_numbers }}+</h3>
                  <p>{{ about.projects }}</p>
                </div>
                <div class="col-md-12">
                  <h3>${{ about.funded_number }}</h3>
                  <p>{{ about.funded_title }}</p>
                </div>
              </div>
              <div class="col-md-6 text-center">
                <div class="col-md-12">
                  <h3>{{ about.growth_number }}%</h3>
                  <p>{{ about.growth_title }}</p>
                </div>
                <div class="col-md-12">
                  <h3>{{ about.revenue_number }}k</h3>
                  <p>{{ about.revenue_title }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 p-4">
            <img src="../assets/images/achievements.jpg" class="aboutimage" />
          </div>
        </div>
      </div>
    </section>
    <section style="padding: 50px 30px; background: #f9f9f9">
      <div class="container">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8 pt-4 text-center">
            <h2>{{ about.value_title }}</h2>
            <p>{{ about.values_description }}</p>
          </div>
          <div class="col-md-2"></div>
          <AboutValues />
        </div>
      </div>
    </section>
    <!-- <section style="padding: 50px 30px">
      <div class="container">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8 pt-4 text-center">
            <h2>{{ about.our_team_title }}</h2>
            <p>{{ about.our_team_description }}</p>
          </div>
          <div class="col-md-2"></div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <Reviews />
          </div>
        </div>
      </div>
    </section> -->
    <section style="padding: 50px 30px">
      <div class="container">
        <div class="row">
          <div class="col-md-12 pt-4 text-center">
            <h2>{{ about.hiring_title }}</h2>
            <p>{{ about.hiring_description }}</p>
            <a href="../careers"><Button :text="buttontText" /></a>
          </div>
        </div>
      </div>
    </section>
    <Blogs />
    <NewsLetter />
  </div>
</template>
<script>
import axios from "axios";
import AboutValues from "@/components/AboutValues.vue";
import Button from "@/components/Button.vue";
import Blogs from "@/components/landingPage/Blogs.vue";
import NewsLetter from "@/components/NewsLetter.vue";
// import Reviews from "@/components/landingPage/Reviews.vue";
export default {
  data() {
    return {
      footer_color: "black",
      buttontText: "View open roles",
      icon_size: 40,
      lang: "en",
      about: {},
    };
  },
  components: {
    Blogs,
    NewsLetter,
    Button,
    // Reviews,
    AboutValues,
  },
  beforeMount() {
    this.ActionUpdateStore();
  },
  methods: {
    ActionUpdateStore() {
      const url =
        "https://au.api.vezham.com/widgets/stringcms/v1/pages/about_us/collections/about/entities/views";
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-WORKSPACE-WALLET-ADDRESS":
            "0wxkALTb6mKr4ydf502UyB8jldFszVYXS1EawFD4S9kMZpG7WLL2bpSwPuVRe49/FMa2voEmo+MlyMF8LzdEw==",
        },
      };
      let data = {
        language_code: this.lang,
      };
      axios
        .post(url, data, config)
        .then(({ data }) => {
          if (data.meta.status != "success") {
            this.about = {};
            return;
          } else if (data.data.length == 0) {
            this.about = {};
            return;
          }
          this.about = data.data[0];
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
@media (max-width: 991px) {
  .About {
    padding: 50px 30px !important;
  }
}
.About {
  padding: 150px 30px 50px 30px;
}
.aboutimage {
  width: 100%;
}
.about-box h3 {
  font-weight: 700;
  font-size: 15px;
  margin-top: 10px;
}
.about-box p {
  line-height: 24px;
  margin-bottom: 0;
}
.about-box svg {
  margin-top: 30px;
}
.about-box {
  text-align: center;
}
</style>
