<template>
  <div
    class="col-lg-3 col-md-6 d-flex align-items-stretch"
    v-for="(value, key) in values"
    :key="key"
  >
    <div class="about-box">
      <ICON_Cube :size="icon_size" :color="footer_color" />
      <h3>{{ value.title }}</h3>
      <p>{{ value.values_description }}</p>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import ICON_Cube from "@/assets/icons/Cube.vue";
export default {
  data() {
    return {
      footer_color: "black",
      icon_size: 40,
      lang: "en",
      values: [],
    };
  },
  components: {
    ICON_Cube,
  },
  beforeMount() {
    this.ActionUpdateStore();
  },
  methods: {
    ActionUpdateStore() {
      const url =
        "https://au.api.vezham.com/widgets/stringcms/v1/pages/about_us/collections/about_values/entities/views";
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-WORKSPACE-WALLET-ADDRESS":
            "0wxkALTb6mKr4ydf502UyB8jldFszVYXS1EawFD4S9kMZpG7WLL2bpSwPuVRe49/FMa2voEmo+MlyMF8LzdEw==",
        },
      };
      let data = {
        language_code: this.lang,
      };
      axios
        .post(url, data, config)
        .then(({ data }) => {
          if (data.meta.status != "success") {
            this.values = [];
            return;
          } else if (data.data.length == 0) {
            this.values = [];
            return;
          }
          this.values = data.data;
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.about-box h3 {
  font-weight: 700;
  font-size: 15px;
  margin-top: 10px;
}
.about-box p {
  line-height: 24px;
  margin-bottom: 0;
}
.about-box svg {
  margin-top: 30px;
}
.about-box {
  text-align: center;
}
</style>
